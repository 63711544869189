<template>
  <PageWarnMessage>
    <span v-if="userStatus <= 4 || userStatus === 6">
      系统检测到您未开户，请先开户
    </span>
    <span v-if="userStatus === 5">申请银行开户</span>
  </PageWarnMessage>
  <PageSection>
    <template v-slot:title>开户类型</template>
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="130"
      label-position="left"
    >
      <Row class="form-row">
        <Col span="12">
          <FormItem label="经办人" prop="agent">
            <Input
              v-model="formValidate.agent"
              placeholder="请输入经办人"
              class="ivu-input-fix"
            ></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="经办人证件号码" prop="agentCredentialsNum">
            <Input
              v-model="formValidate.agentCredentialsNum"
              placeholder="请输入经办人证件号码"
              class="ivu-input-fix"
            ></Input>
          </FormItem>
        </Col>
      </Row>
      <Row class="form-row">
        <Col span="12">
          <FormItem label="经办人手机号码" prop="agentNumber">
            <Input
              v-model="formValidate.agentNumber"
              placeholder="请输入经办人手机号码"
              class="ivu-input-fix"
            ></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <Row align="middle">
      <Button type="error" class="commit-btn" @click="commitClick">提交</Button>
      <!-- <span>开户链接已生成，请点击进入开户</span>
      <Button type="text" class="button-link">立即前往</Button> -->
    </Row>
  </PageSection>
</template>

<script>
export default {
  name: 'OpenAcount',
  data () {
    return {
      formValidate: {
        agent: null, // 经办人
        agentCertType: 1, // 代办人证件类型 1:身份证
        agentCredentialsNum: null, // 经办人证件号码
        agentNumber: null, // 经办人手机号码
        // agentEmail: null, // 经办人电子邮箱
      },
      ruleValidate: {
        accountCode: [
          { required: true, message: '用户代码不能为空', trigger: 'blur' },
        ],
        account: [
          {
            required: true,
            message: '交易所账户不能为空',
            trigger: 'blur',
          },
        ],
        phoneNumer: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'blur',
          },
        ],
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        credentialsType: [
          { required: true, message: '证件类型不能为空', trigger: 'change' },
        ],
        email: [{ required: true, message: '邮箱不能为空', trigger: 'blur' }],
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        accountType: [
          { required: true, message: '账户类型不能为空', trigger: 'blur' },
        ],
        companyCredentialsType: [
          { required: true, message: '公司证件类型不能为空', trigger: 'blur' },
        ],
        companyCredentialsNum: [
          { required: true, message: '公司证件号码不能为空', trigger: 'blur' },
        ],
        legalPerson: [
          { required: true, message: '法人代表不能为空', trigger: 'blur' },
        ],
        legalPersonCredentialsType: [
          { required: true, message: '法人证件类型不能为空', trigger: 'blur' },
        ],
        legalPersonCredentialsNum: [
          { required: true, message: '法人证件号码不能为空', trigger: 'blur' },
        ],
        agent: [{ required: true, message: '经办人不能为空', trigger: 'blur' }],
        agentCredentialsNum: [
          {
            required: true,
            message: '经办人证件号码不能为空',
            trigger: 'blur',
          },
        ],
        agentNumber: [
          {
            required: true,
            message: '经办人手机号码不能为空',
            trigger: 'blur',
          },
        ],
        agentEmail: [
          {
            required: true,
            message: '经办人电子邮箱不能为空',
            trigger: 'blur',
          },
        ],
      },
      userStatus: '',
    }
  },
  created () {
    this.userStatus = this.$store.state.member.memberCenter.status
  },
  methods: {
    commitClick () {
      this.$refs.formValidate.validate((valid) => {
        if (!valid) {
          this.$Message.error('请填写必填项!')
          return
        }
        this.openAcount()
        this.$Spin.show({
          render: (h) => {
            return h('div', [
              h(
                'div',
                {
                  style: {
                    color: '#d12b23',
                    fontSize: '22px',
                  },
                },
                '正在启动银行开户请不要离开页面。否则无法完成开户...',
              ),
            ])
          },
        })
        setTimeout(() => {
          // this.$Spin.hide()
          this.getWithdrawUrl()
        }, 5000)
      })
    },
    openAcount () {
      this.$store
        .dispatch('accountMoney/OpenAcount', this.formValidate)
        .then((res) => {
          if (res.returnCode === '1') {
          //
          } else {
						this.$Spin.hide()
					}
        })
        .catch(() => {})
    },
    getWithdrawUrl () {
      const params = {}
      this.$store
        .dispatch('accountMoney/GetWithdrawUrl', params)
        .then((res) => {
          if (res.returnCode === '1') {
            // this.authUrl = res.result.authUrl
            if (res.result.authUrl) {
                  window.open(res.result.authUrl, '_self')
                }
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.form-row {
  padding-left: 10px;
  .ivu-input-fix {
    width: 80%;
  }
}
.commit-btn {
  width: 120px;
  margin-right: 30px;
}
</style>
