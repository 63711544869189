<template>
    <PageTitleTop>
        <template v-slot:title>开户</template>
        <FormList v-if="showOpenAcount"/>
        <div v-else>
            <div v-if="status <= 5" class="main-content">
                <img :src="require('/static/icons/error.png')" alt="" />
                <span class="secondary-info" >未检测到开户状态</span>
                <span>系统未检测到您的开户状态，请核实后重新开户</span>
                <a @click="toOpenAcount">去开户</a>
            </div>
            <div v-if="status === 6" class="main-content">
                <img :src="require('/static/icons/error.png')" alt="" />
                <span class="secondary-info" >检测到开户失败</span>
                <span>开户失败，请重新开户</span>
                <a  @click="toOpenAcount">重新开户</a>
            </div>
        </div>
    </PageTitleTop>
</template>

<script>
import FormList from './components/FormList'
export default {
    name: 'AccountBalance',
    data () {
        return {
            showOpenAcount: false,
            status: -1
        }
    },
    created () {
        this.status = this.$store.state.member.memberCenter.status
        this.showOpenAcount = this.status <= 5 && this.status !== 6
    },
    methods: {
        toOpenAcount () {
            this.showOpenAcount = true
        }
    },
    components: {
        FormList
    }
}
</script>

<style lang="less" scoped>
.main-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 400px;
    // border: 1px solid #dcdfe6;
    span,
    a {
      height: 40px;
      line-height: 40px;
    }
    a {
        color: #58aaff;
        margin-top: 20px;
    }
    img {
      width: 50px;
      height: 50px;
    }
}
</style>
